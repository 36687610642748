export default {
    title: 'Gross Inventory Differences',
    location: '/business/fuo/gross-inventory-differences',
    tabs: [
        {
            name: 'By Country',
            link: `/business/fuo/gross-inventory-differences`
        },
        {
            name: 'Trend',
            link: `/business/fuo/gross-inventory-differences/:market`
        },
        {
            name: 'By Country (Store)',
            link: `/business/fuo/gross-inventory-differences-store`
        },
        {
            name: 'Trend (Store)',
            link: `/business/fuo/gross-inventory-differences-store/:market`
        }
    ]
}
